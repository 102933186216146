import React from 'react';
import PropTypes from 'prop-types';
import { MARKETS } from '../common/constants';
import { injectIntl } from 'gatsby-plugin-intl';
import { paramCase } from 'change-case';
import { Link } from 'gatsby';

const MarketGuideBlockMobile = ({ trackingSection, country, intl, homePage }) => {
    const { locale } = intl;
    const countries = homePage ? MARKETS : MARKETS.filter(market => market !== country);

    return (
        <div className="block standard market-guide-block" data-section={trackingSection} style={{ overflowX: 'scroll' }}>
          <div className="container-fluid">
                <h2 className="green">{intl.formatMessage({ id: 'footer_heading' })}</h2>
                <div className="additional-markets-slider mobile">
                  {countries.map(market => (
                      <div className="market-item" key={market}>
                        <div className={`country market-cta ${paramCase(market)}`}>
                          <Link to={`/${locale}/market-guide/${paramCase(market)}`}>
                            <div className="content">
                              <span className="market-title">{intl.formatMessage({ id: `${market}_nav_1` })}</span>
                              <span className="button secondary">{intl.formatMessage({ id: 'cta_preview' })}</span>
                            </div>
                          </Link>
                        </div>
                      </div>
                  ))}
              </div>
          </div>
        </div>
    );
}

export default injectIntl(MarketGuideBlockMobile);
