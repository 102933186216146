import React, { useState, useEffect, useRef } from "react"
import classNames from "classnames"
import { useInView } from "react-hook-inview"

const Trends = props => {
  const {
    image,
    snippet,
    heading,
    paras,
    cssClass,
    getPaginationData,
    trackingSection,
  } = props
  const pagRef = useRef(null);
  const [ref, isVisible] = useInView({
    threshold: 0.8,
  })
  const [animationRan, setAnimationRan] = useState(false)

  useEffect(() => {

    if (isVisible && !animationRan) {
      setAnimationRan(true)
    }
  })

  useEffect(() => {
    const section = window.location.pathname.includes('market-guide') ? 'Market Tip' : 'The Payment Shift';
    if (pagRef.current) getPaginationData(section, pagRef);
  }, [pagRef]);

    return (
        <div
            className={classNames(
                "block",
                "split-block",
                cssClass,
            )}
            ref={pagRef}
            data-section={trackingSection}
        >
            <div className="image">
                <div
                    ref={ref}
                    className={classNames('image-zoom', { active: animationRan })}
                    style={{
                        backgroundImage: `url(${image})`
                    }}
                />
            </div>
            <div className="container-fluid">
              <div className="row">
                  <div className="content col-md-6">
                      {snippet ?
                          <p className="snippet">
                              {snippet}
                          </p>
                          : null }

                      <h2>{heading}</h2>
                      <div dangerouslySetInnerHTML={{ __html: paras }} />
                  </div>
              </div>
            </div>
        </div>

  )
}

export default Trends
