import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import { injectIntl } from 'gatsby-plugin-intl';
import { MARKETS } from '../common/constants';
import { paramCase } from 'change-case';
import { Controller, Scene } from 'react-scrollmagic';
import { Timeline, Tween } from 'react-gsap';
import useWindowSize from '../util/useWindowSize';

const MarketGuideBlock = ({ country, homePage, intl, trackingSection }) => {
    const countries = homePage ? MARKETS : MARKETS.filter(market => market !== country);
    const { width } = useWindowSize();
    const { locale } = intl;
    const slides = useRef(null);

    const [slideWidth, setSlideWidth] = useState(0);

    useEffect (() => {
        const { current } = slides;
        let slidesWidth = 0;
        const blockStyle = window.getComputedStyle ? getComputedStyle(current, null) : current.currentStyle;

        const blockPaddingLeft = parseInt(blockStyle.paddingLeft) || 0;
        const blockPaddingRight = parseInt(blockStyle.paddingRight) || 0;

        Array.from(current.querySelectorAll('.market-item')).forEach(item => {
            const style = window.getComputedStyle ? getComputedStyle(item, null) : item.currentStyle;
            const marginLeft = parseInt(style.marginLeft) || 0;
            const marginRight = parseInt(style.marginRight) || 0;
            const itemWidth = marginLeft + marginRight + item.clientWidth;

            slidesWidth = slidesWidth + itemWidth;
        });

        setSlideWidth(`-${slidesWidth - width + blockPaddingRight + blockPaddingLeft + 100}px`);
    }, [width]);

    return (
      <div>
          <Controller>
              <Scene
                  duration="400%"
                  pin
                  triggerHook=".1"
                  pinSettings={{
                      pushFollowers: true
                  }}

              >
                  <Timeline>
                      <Tween>
                       <div>
                      <div className="block standard market-guide-block" data-section={trackingSection}>
                        <div className="container-fluid">
                          <h2 className="green">{intl.formatMessage({ id: 'footer_heading' })}</h2>
                              <Tween
                                  to={{
                                      translateX: slideWidth
                                  }}
                              >
                              <div>
                                  <div className="additional-markets-slider desktop" ref={slides}>
                                    {countries.map(market => (
                                        <div className="market-item" key={market}>
                                          <div className={`country market-cta ${paramCase(market)}`}>
                                            <Link to={`/${locale}/market-guide/${paramCase(market)}`}>
                                              <div className="content">
                                                <span className="market-title">{intl.formatMessage({ id: `${market}_nav_1` })}</span>
                                                <span className="button secondary">{intl.formatMessage({ id: 'cta_preview' })}</span>
                                              </div>
                                            </Link>
                                          </div>

                                        </div>
                                    ))}
                                </div>
                            </div>
                          </Tween>
                        </div>
                      </div>
                  </div>
                </Tween>
                </Timeline>
            </Scene>
        </Controller>
      </div>
    );
}

MarketGuideBlock.propTypes = {
    country: PropTypes.string,
    homePage: PropTypes.bool,
};

MarketGuideBlock.defaultProps = {
    country: '',
    homePage: false,
};

export default injectIntl(MarketGuideBlock);
