import React, { useState, useEffect, useRef } from 'react';
import classNames from 'classnames';
import { useInView } from 'react-hook-inview';

const Summary = props => {
  const { background, heading, heading2, para1, para2, getPaginationData, trackingSection } = props;
  const pagRef = useRef(null);
  const [ref, isVisible] = useInView({
    threshold: 0.8,
  })

  const [animationRan, setAnimationRan] = useState(false);

  useEffect(() => {
    if (isVisible && !animationRan) {
      setAnimationRan(true);
    }
  })

  useEffect(() => {
    const section = window.location.pathname.includes('market-guide') ? 'The Latin American Market' : 'The Pandemic Effect';
    if (pagRef.current) getPaginationData(section, pagRef);
  }, [pagRef]);

  return (
    <div className="block summary standard" ref={pagRef} data-section={trackingSection}>
      <div
        className={classNames('background image-zoom', { active: animationRan })}
        ref={ref}
        style={{
          backgroundImage: `url(${background})`,
        }}
      />
      <div className="container-fluid">
        <div className="row">
          <div className="content col-xl-6">
            <h2>{heading}</h2>
            {heading2 !== "null" ? <h2>{heading2}</h2> : null}
            <div dangerouslySetInnerHTML={{ __html: para1 }} />
            <div dangerouslySetInnerHTML={{ __html: para2 }} />
          </div>
        </div>
      </div>
    </div>
  )
}
export default Summary
