import React from "react"
import { injectIntl } from "gatsby-plugin-intl"
import Link from './link';

const Boilerplate = ({ intl, trackingSection }) => {
  const footer_1 = intl.formatMessage({
      id: "footer_header_1",
      defaultMessage: "null",
  })

  return (
      <div className="block standard footer-cta" data-section={trackingSection}>
        <div className="container-fluid">
          <div className="row">
            <div className="col col-xl-9">
              <h2>{intl.formatMessage({ id: "footer_header" })}</h2>
              {footer_1 !== "null" ? <h2>{footer_1}</h2> : null}
            </div>
          </div>
          <div className="row">
            <div className="col-xl-4">
              <h5>{intl.formatMessage({ id: "footer_subheading1" })}</h5>
              <p>{intl.formatMessage({ id: "footer_subcopy1" })}</p>
              <p>
                <strong>{intl.formatMessage({ id: "footer_subheading3" })}</strong>
              </p>
              <p className="small">
                {intl.formatMessage({ id: "footer_subcopy3" })}
              </p>
            </div>
            <div className="col-xl-4">
              <p>
                <strong>{intl.formatMessage({ id: "footer_subheading2" })}</strong>
              </p>
              <p className="small">
                {intl.formatMessage({ id: "footer_subcopy2" })}
              </p>
              <Link
                className="button primary"
                to="/download"
                title={intl.formatMessage({ id: "cta_download" })}
              >
                {intl.formatMessage({ id: "cta_download" })}
              </Link>
            </div>
          </div>
        </div>
      </div>
  )
}

export default injectIntl(Boilerplate)
