import React, { useRef, useEffect } from 'react';
import { Tween, Timeline } from "react-gsap"
import { Controller, Scene } from "react-scrollmagic"

/*
SLIDING STATS Notes
On scroll:
- .sliding-stat locks to the top of the users screen and cycles through multiple stats
- Background image on the .sliding-stat::before needs to change
- Content needs to change
- .ecom class makes text geeen
- .pos makes text purple
*/
const SlidingStat = (props) => {
    const ref = useRef(null);
    const {
        title,
        stat1Title,
        stat1Value,
        stat1Description,
        stat2Title,
        stat2Value,
        stat2Description,
        stat1Image,
        stat2Image,
        getPaginationData,
        trackingSection,
    } = props;

    useEffect(() => {
      const section = window.location.pathname.includes('market-guide') ? 'Payments Forecast' : 'Predictions';
      if (ref.current) getPaginationData(section, ref);
    }, [ref]);

    return (
        <div ref={ref} data-section={trackingSection}>
            <div className="block title green">
              <div className="container-fluid">
                <div className="row">
                    <div className="col-xl-9">
                        <h2>{title}</h2>
                    </div>
                </div>
              </div>
            </div>
            <Controller>
                <Scene
                    duration={2000}
                    pin
                    triggerHook="0"
                >
                    <Timeline>
                        <div className="block block-mid sliding-stat">
                            <div className="images">
                                <Tween
                                    to={{
                                        translateY: "-50%"
                                    }}
                                    position={1}
                                >
                                <div className="slider">
                                    <div className="image image-1" style={{
                                        backgroundImage: `url(${stat1Image})`
                                    }} />
                                    <div className="image image-2" style={{
                                        backgroundImage: `url(${stat2Image})`
                                    }} />
                                </div>
                                </Tween>
                            </div>
                            {/* <Tween
                                to={{
                                    top: "100%"
                                }}
                                position={1}
                            >
                                <div className="image-1" style={{
                                    backgroundImage: `url(${stat1Image})`
                                }} />
                            </Tween>
                            <Tween
                                to={{
                                    top: "0"
                                }}
                                position={1}
                            >
                                <div className="image-2" style={{
                                    backgroundImage: `url(${stat2Image})`
                                }} />
                            </Tween> */}
                            <div className="row">
                                <div className="content col-md-6 offset-md-6">
                                    <Tween
                                        from={{
                                            position: "absolute",
                                            translateY: "-50%",
                                            top: "50%",

                                        }}
                                        to={{
                                            translateY: "-100%",
                                            opacity: 0
                                        }}
                                        position="1"
                                    >
                                        <div className="ecom">
                                            <h4>
                                                {stat1Title}
                                            </h4>
                                            <span className="xl-stat">{stat1Value}</span>
                                            <div dangerouslySetInnerHTML={{ __html: stat1Description}} />
                                        </div>
                                    </Tween>
                                    <Tween
                                        from={{
                                            position: "absolute",
                                            translateY: "50%",
                                            top: "50%",
                                            opacity: 0,

                                        }}
                                        to={{
                                            opacity: 1,
                                            translateY: "-50%"
                                        }}
                                        position="1"
                                    >
                                        <div className="pos">
                                            <h4>
                                                {stat2Title}
                                            </h4>
                                            <span className="xl-stat">{stat2Value}</span>
                                            <div dangerouslySetInnerHTML={{ __html: stat2Description }} />
                                        </div>
                                    </Tween>
                                    <Tween duration={2}><div></div></Tween>
                                </div>
                            </div>
                        </div>

                    </Timeline>
                </Scene>
            </Controller>
        </div>
    )
}

export default SlidingStat;
