/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from "react"
import Header from "./header"
import { injectIntl } from 'gatsby-plugin-intl';

const Layout = ({ intl, children }) => {
  if (typeof window === "undefined") {
    return (<></>);
  }

  const { locale } = intl;
  return (
    <div className={locale}>
      <Header />
      {children}
    </div>
  )
}

export default injectIntl(Layout)
