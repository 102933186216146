import React, { useState, useEffect } from 'react';
import { injectIntl } from 'gatsby-plugin-intl';
import classNames from 'classnames';
import scrollToComponent from 'react-scroll-to-component';
import _ from 'lodash';

const Pagination = ({ items, intl }) => {
    const [active, setActive] = useState(items[0].text);

    useEffect(() => {
        window.addEventListener('scroll', () => {
            if (items[0].scrollTo) {
                // filter out whats not in the viewport
                const filtered = items.filter(item => item.scrollTo.getBoundingClientRect().bottom >= 0);
                // sort the array by bottom position in viewport and use the smallest value
                const section = filtered.sort((a, b) => (
                    a.scrollTo.getBoundingClientRect().bottom - b.scrollTo.getBoundingClientRect().bottom
                ))[0];

                if (section) setActive(section.text);
            }
        });

        return () => {
            window.removeEventListener('scroll', () => {});
        }
    }, []);

    return (
        <div className="pagination">
            <ul>
                {items.map(item => {
                    const { text, scrollTo, id } = item;

                    return (
                        <li
                            className={classNames({ active: active === text })}
                            onClick={() => {
                                scrollToComponent(scrollTo, {
                                	offset: 0,
                                	align: 'top',
                                });
                            }}
                            key={id}
                        >
                            <span>{intl.formatMessage({ id })}</span>
                        </li>
                    )
                })}
            </ul>
        </div>
    );
}

export default injectIntl(Pagination);
