import React from "react"
import { injectIntl } from "gatsby-plugin-intl"
import Link from './link';

const Cta = props => {
  const { header, content, button, trackingSection } = props;
  // const { pathname } = window.location;

  return (
    <div className="block standard cta" data-section={trackingSection}>
      <div className="container-fluid">
        <div className="row">
          <div className="col-xl-8">
            <h3>{header}</h3>
            <span dangerouslySetInnerHTML={{ __html: content }} />
            <ul>
              <li>
                <Link
                  className="button primary"
                  to="/download"
                  title="Download Report"
                >
                  {button}
                </Link>
              </li>
              {/*

              <li>
                {!pathname.includes('market-guide') ?
                  <Link
                    className="button secondary"
                    to="/market-guide"
                    title="Preview Market Guide"
                  >
                    {intl.formatMessage({ id: 'cta_preview' })}
                  </Link>
                : null}
              </li>

              */}
            </ul>
          </div>
        </div>
      </div>
    </div>
  )
}

export default injectIntl(Cta);
